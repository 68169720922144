// 找到指定tag的父元素
export const parentUntil =  function(node, tagName){
  let t = tagName.toUpperCase()

  let target = node.parentNode
  while(true){
    if(target==null || target.tagName === t) break
    target = target.parentNode
  }
  return target
}
// 序列化多层object/array成url的query
export function serializeQuery(params, prefix) {
  const query = Object.keys(params).map((key) => {
    const value  = params[key];

    if (params.constructor === Array)
      key = `${prefix}[]`;
    else if (params.constructor === Object)
      key = (prefix ? `${prefix}[${key}]` : key);

    if (typeof value === 'object')
      return serializeQuery(value, key);
    else
      return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join('&');
}
export function createDelayFunction(fn, timeout = 300) {
  let timeoutId = -1;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn.apply(null, args);
    }, timeout);
  }
}