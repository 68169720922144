import { Controller } from "stimulus"

export default class extends Controller {
  intercept(event) {
    const {url} = event.detail.fetchResponse.response;
    if (url.match("runtime/project_users/projects/\\d+/sign_in")) {
      event.preventDefault()
      window.location = window.location.href
    }
  }
}