import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['input', 'form']

    // connect () {
    //     console.log('project import loaded!', this.formTarget)
    // }
    select_file() {
        this.inputTarget && this.inputTarget.click();
    }

    save() {
        this.formTarget.submit()
    }
}
