import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['needPassword', 'passwordInput']

    connect () {
        this.change_need()
    }
    change_need() {
        if(this.needPasswordTarget.checked){
            this.passwordInputTarget.classList.remove('hidden')
        }else{
            this.passwordInputTarget.classList.add('hidden')
        }
    }
}
