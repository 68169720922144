import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    console.log("connected")
  }

  switch(event) {
    console.log("switch user list")
    let href = window.location.href

    if (event.target.selectedOptions[0].value !== 'discarded') {
      window.location = href.replace(/discarded=[^&]/, '')
    } else {
      if (!href.includes('discarded=')) {
        if (href.includes('?')) {
          href += '&discarded=1'
        } else {
          href += '?discarded=1'
        }
      }

      window.location = href
    }
  }
}
