import { Controller } from "stimulus";
import {createApp, onMounted, ref, computed, watchEffect, triggerRef, shallowRef} from 'vue/dist/vue.esm-bundler.js';

function keygenGet(uri) {
  return fetch(`/admin/keygen_get?uri=${encodeURIComponent(uri)}`)
}

function findEntitlement(entitlements) {
  return entitlements?.reduce((r, e) => {
    if (e?.attributes.code.endsWith("_TAGS")) {
      return (e?.attributes.metadata?.tags || 0) > (r?.attributes.metadata?.tags || 0) ? e : r
    } else {
      return r
    }
  }, undefined)
}

export default class ProjectLicensesController extends Controller {
  connect() {
    const devPolicy = this.element.dataset.developmentPolicy
    const standardPolicy = this.element.dataset.standardPolicy
    
    createApp({
      setup(props, {expose}) {
        const loadingStatus = ref(0)
        const licenses = ref()

        onMounted(() => {
          fetch('licenses_content').then(r => r.json()).then((data) => {
            licenses.value = data
            for (let i = 0, l = data.length; i < l; i++) {
              const {
                relationships: { 
                  machines: { links: {related: machinesUri}},
                  entitlements: { links: { related: entitlementsUri }}
                }
              } = data[i]
              keygenGet(machinesUri).then(r => r.json()).then(({data}) => {
                licenses.value[i].machines = data
              })
              keygenGet(entitlementsUri).then(r => r.json()).then(({data}) => {
                licenses.value[i].entitlements = data
              })
            }
          })
        })

        return {
          loadingStatus,
          licenses,
          findEntitlement,
          policyText(policy) {
            switch(policy) {
              case devPolicy:
                return '试用版'
              case standardPolicy:
                return '标准版'
              default:
                return "未知"
            }
          }
        }
      }
    }).mount(this.element)
  }
}
