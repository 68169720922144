import axios from 'axios'
const axiosInstance = axios.create({
  baseURL: '/admin',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

axiosInstance.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  return Promise.reject(error);
});

export const updateI18nItems = (id, value) => {
  return axiosInstance.put(`/i18n_items/${id}`, { value })
}

