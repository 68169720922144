// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as t from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import axios from 'axios'
import "channels"
import '../tools'
import "./layout"
import "@fortawesome/fontawesome-free/css/all.css";
import "@fontsource/cooper-hewitt/all";
import "./application.scss"

window.Turbo = t.Turbo
window.axios = axios
ActiveStorage.start()
Date.prototype.toString = function () {
  return this.toISOString()
}
import "controllers"

function htmlStringToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

//临时方案，electron里有个bug，使用了原生的confirm之后，会导致下一个页面的input没法使用，所以先自己写一个
function confirm1({content, onOk}) {
  function remove() {
    if (document.getElementById('custom-dialog')) {
      document.getElementById('dialog-ok').removeEventListener('click', ok)
      document.getElementById('dialog-cancel').removeEventListener('click', remove)
      document.body.removeChild(document.getElementById('custom-dialog'))
      document.body.removeChild(document.getElementById('dialog-mask'))
    }
  }

  function ok() {
    onOk()
    remove()
  }

  let dialogBox = document.getElementById('custom-dialog');
  if (!dialogBox) {
    dialogBox = document.createElement('div');
    dialogBox.id = 'custom-dialog';
    const dialogBody = `
<div style="display: flex; flex-direction: column; height: 100%;">
   <div style="flex: 1; font-size: 16px;">${content}</div>
   <div style="display: flex; justify-content: flex-end;">
    <button class="btn" id="dialog-cancel">取消</button>
    <button class="btn btn-primary" id="dialog-ok">确定</button>
   </div>        
</div> 
            `
    dialogBox.appendChild(htmlStringToElement(dialogBody))
    document.body.appendChild(htmlStringToElement(
        `
<div 
id="dialog-mask" 
style="position: fixed;
top: 0;
left: 0;
width: 100vw; 
height: 100vh; overflow: hidden; 
background: rgba(0,0,0,.15); 
z-index: 9998">
</div>
        `
    ));
    document.body.appendChild(dialogBox);
    document.getElementById('dialog-ok').addEventListener('click', ok)
    document.getElementById('dialog-cancel').addEventListener('click', remove)
  }
}

window.confirm1 = confirm1

let __SkipConfirmation = false;

//把Rails的confirm默认行为也覆盖掉
Rails.confirm = function (message, element) {
  if (__SkipConfirmation) {
    return true;
  }

  function onConfirm() {
    __SkipConfirmation = true;
    if (['post', 'delete', 'put'].includes(element.getAttribute('method'))) {
      element.submit();
    } else {
      element.click();
    }
    __SkipConfirmation = false;
  }

  confirm1({content: message, onOk: onConfirm});

  return false;
};

Rails.start()
