import { Controller } from "stimulus"
import { parentUntil } from '../helpers'
export default class extends Controller {
    static targets = ['tbody']

    connect () {
        let tbody = this.tbodyTarget
        this.records = JSON.parse(tbody.dataset.records)||[]
        this.project_id = tbody.dataset.projectId
        this.add_idx = 0
        this.records.forEach(r=>{
            tbody.append(this.generate_tr(r))
        })
        if(this.records.length===0) tbody.append(this.generate_tr())
        this.reCalTdIdx()
    }
    add() {
        this.tbodyTarget.append(this.generate_tr())
        this.reCalTdIdx()
    }
    reCalTdIdx(){
        this.tbodyTarget.querySelectorAll('.td_idx').forEach((dom, idx)=>{
          dom.innerText = idx+1
        })
    }
    generate_tr(obj = {}){
        let dom = document.createElement('tr')
        let td1 = document.createElement('td')
        td1.classList.add('td_idx')
        dom.appendChild(td1)

        let varTd = document.createElement('td')
        let varSelect = document.createElement('select')
        varSelect.style.width='100%'
        varSelect.name=`items[variable_id][${this.add_idx}]`
        varSelect.classList.add('form-control')
        //controller: 'variable-select2', 'variable-select2-target': 'select', project_id: @project.id
        varSelect.setAttribute('data-controller', 'variable-select2')
        varSelect.setAttribute('data-project-id', this.project_id)
        varSelect.setAttribute('data-variable-select2-target', 'select')
        varSelect.setAttribute('data-params', JSON.stringify({with_addr: 1}))
        if(obj.variable_name){
          varSelect.innerHTML = `<option selected value="${obj.variable_id}">${obj.variable_name}</option>`
        }

        varTd.append(varSelect)
        dom.appendChild(varTd)

        let nameTd = document.createElement('td')
        let nameInput = document.createElement('input')
        nameInput.type='text'
        nameInput.name=`items[alias][${this.add_idx}]`
        nameInput.classList.add('form-control')
        nameInput.value = obj.alias || ''
        nameTd.append(nameInput)
        dom.appendChild(nameTd)

        let btn_td = document.createElement('td')
        btn_td.classList.add('text-center')
        btn_td.innerHTML = `<a class="btn btn-danger btn-sm del-btn" data-action="parameter-storage-config-edit#remove" href="javascript: void(0)"><i class="fas fa-minus"></i></a>`
        dom.appendChild(btn_td)
        this.add_idx++
        // this.reCalTdIdx()
        return dom
    }
    batch_save(e){
        let form = parentUntil(e.target, 'form')
        form.submit()
    }
    after_save(e){
        if(e.detail[2] && e.detail[2].status===200){

        }
        console.log('after save', e.detail)
    }
    remove(e){
        let tr = parentUntil(e.target, 'tr')
        tr.remove()
        this.reCalTdIdx()
    }
}
