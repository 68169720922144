import { Controller } from 'stimulus'
import { parentUntil } from '../helpers'

export default class extends Controller {
  static targets = ['auto', 'autoShow', 'autoHide', 'recordConditions', 'recordConditionsInForm', 'subStages', 'subStagesInForm']

  connect () {
    this.change()
    this.project_id = this.recordConditionsInFormTarget.dataset.projectId
    let conditions = JSON.parse(this.recordConditionsInFormTarget.value)||[]
    conditions.forEach(r=> this.addRecordCondition(r))
    let sub_stages = JSON.parse(this.subStagesInFormTarget.value)||[]
    sub_stages.forEach(r=> this.addSubStage(r))
  }

  addRecordCondition(obj = {}){
    let dom = document.createElement('div')
    dom.classList.add('condition-item', 'my-1')
    let select_options = ''
    if (obj.variable_id!=null && obj.variable_id!==''){
      select_options = `<option value="${obj.variable_id}" selected>${obj.variable_name}</option>`
    }
    dom.innerHTML = `
      条件变量：
        <select data-controller="variable-select2" data-variable-select2-target='select' data-project-id="${this.project_id}" style="width: 400px">
            ${select_options}
        </select>
      范围值：
        <input type="text" value="${obj.ge||''}" name="ge" class="form-control" style="display: inline-block;width: 100px"/>
        ~
        <input type="text" value="${obj.le||''}" name="le" class="form-control" style="display: inline-block;width: 100px"/>
        
      <a href="javascript:" class="btn btn-danger" data-action="batch-rule-form#remove"><i class="fas fa-times"></i></a> 
    `
    this.recordConditionsTarget.append(dom)
  }

  addSubStage(obj){
    let dom = document.createElement('div')
    dom.classList.add('sub-stage-item', 'my-1')
    let select_options = ''
    if (obj.variable_id!=null && obj.variable_id!==''){
      select_options = `<option value="${obj.variable_id}" selected>${obj.variable_name}</option>`
    }
    dom.innerHTML = `
      <input type="text" value="${obj.name||''}" name="name" class="form-control" placeholder="阶段名" style="display: inline-block;width: 100px"/>
      条件变量：<select data-controller="variable-select2" data-variable-select2-target='select' data-project-id="${this.project_id}" style="width: 400px">${select_options}</select>
      范围值：<input type="text" value="${obj.ge||''}" name="ge" class="form-control" style="display: inline-block;width: 100px"/>
      ~
      <input type="text" value="${obj.le||''}" name="le" class="form-control" style="display: inline-block;width: 100px"/>
      <a href="javascript:" class="btn btn-danger" data-action="batch-rule-form#remove"><i class="fas fa-times" ></i></a>
    `
    this.subStagesTarget.append(dom)
  }

  submit(e){
    let conditionRes = []
    let stageRes = []
    let condition_items = this.recordConditionsTarget.querySelectorAll('.condition-item')
    for(let i=0;i<condition_items.length;i++){
      let e = condition_items[i]
      let variable_id = e.querySelector('select').value
      if(variable_id==null||variable_id===''){
        return alert('请选择条件变量后保存')
      }
      let ge = e.querySelector('input[name="ge"]').value
      let le = e.querySelector('input[name="le"]').value
      conditionRes.push({variable_id, ge, le})
    }
    let sub_stages = this.subStagesTarget.querySelectorAll('.sub-stage-item')
    for(let i=0;i<sub_stages.length;i++) {
      let e = sub_stages[i]
      let variable_id = e.querySelector('select').value
      if(variable_id==null||variable_id===''){
        return alert('请选择条件变量后保存')
      }
      let ge = e.querySelector('input[name="ge"]').value
      let le = e.querySelector('input[name="le"]').value
      let name = e.querySelector('input[name="name"]').value
      if(name==null||name===''){
        alert('请输入阶段名称')
        return e.querySelector('input[name="name"]').focus()
      }
      stageRes.push({ variable_id, ge, le, name })
    }
    this.recordConditionsInFormTarget.value = JSON.stringify(conditionRes)
    this.subStagesInFormTarget.value = JSON.stringify(stageRes)
    parentUntil(e.target, 'form').submit()
  }
  remove(e) {
    e.target.closest('.sub-stage-item, .condition-item').remove()
  }
  change () {
    if (this.autoTarget.checked) {
      this.autoShowTargets.forEach(t => t.classList.add('hidden'))
      this.autoHideTargets.forEach(t => t.classList.remove('hidden'))
    } else {
      this.autoShowTargets.forEach(t => t.classList.remove('hidden'))
      this.autoHideTargets.forEach(t => t.classList.add('hidden'))
    }
  }
}
