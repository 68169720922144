import { Controller } from "stimulus"

export default class extends Controller {
  connected = false
  current_project = {}
  static targets = ["ip", "ipStatus", "operationBlock", "operationLog", "currentProjectInfo"]
  connect() {
    if(window.scada_app == null) {
      alert('不支持云端模式运维')
      return window.close()
    }
    scada_app.receive('maintenance_data', this.process_app_data.bind(this))
  }
  disconnect(){
    console.log('leave!!')
  }
  process_app_data(obj){
    console.log('receive', obj)
    if(obj.type==='ip_status'){
      let ping = obj.data.data
      let project = obj.data.project
      this.current_project = project
      // obj: { type: 'ip_status', data: { data: true, project: {name: '', id: , updated_at: ''} }}
      this.setIpStatus(ping)
      let project_div = this.currentProjectInfoTarget
      if(project){
        let t = dateFormat(new Date(project.updated_at), "yyyy-MM-dd HH:mm:ss")
        project_div.innerText = `${project.name}（id: ${project.id}, 更新时间：${t}）`
      }else{
        project_div.innerText = "当前HMI未运行任何项目"
      }
    }else if(obj.type==='maintenance_result'){
      let d = document.createElement('div')
      d.innerText = `[${dateFormat(new Date(),"yyyy-MM-dd HH:mm:ss")}] `+(typeof obj.data === 'string' ? obj.data : JSON.stringify(obj.data))
      this.operationLogTarget.appendChild(d)
    }else if(obj.type==='maintenance_error'){
      let d = document.createElement('div')
      d.style.color = 'red'
      d.innerText = `[${dateFormat(new Date(),"yyyy-MM-dd HH:mm:ss")}] `+(typeof obj.data === 'string' ? obj.data : JSON.stringify(obj.data))
      this.operationLogTarget.appendChild(d)
    }
  }
  setIpStatus(f){
    this.connected = f
    if(this.connected){
      this.ipStatusTarget.classList.remove('fa-times', 'text-danger')
      this.ipStatusTarget.classList.add('fa-check', 'text-success')
      this.operationBlockTarget.classList.remove('hidden')
    }else{
      this.ipStatusTarget.classList.remove('fa-check', 'text-success')
      this.ipStatusTarget.classList.add('fa-times', 'text-danger')
      this.operationBlockTarget.classList.add('hidden')
    }
  }
  hide_block(){
    if(!this.operationBlockTarget.classList.contains('hidden')) this.operationBlockTarget.classList.add('hidden')
  }
  try_ip(){
    let ip = this.ipTarget && this.ipTarget.value
    scada_app.send('maintenance_action', {action: 'check_ip', ip})
  }

  clear_db(){
    let ip = this.ipTarget && this.ipTarget.value
    if(confirm('确定操作？')) scada_app.send('maintenance_action', {action: 'clear_db', ip})
  }
  reset_users(){
    let ip = this.ipTarget && this.ipTarget.value
    if(confirm('确定操作？')) scada_app.send('maintenance_action', {action: 'reset_users', ip})
  }
  reset_audit_trail(){
    let ip = this.ipTarget && this.ipTarget.value
    if(confirm('确定操作？')) scada_app.send('maintenance_action', {action: 'reset_audit_trail', ip})
  }
  view_scada(){
    let ip = this.ipTarget && this.ipTarget.value
    if(this.current_project && this.current_project.id){
      let url = `http://${ip}/draw/?project_id=${this.current_project.id}&force_socket=1#/runtime`
      window.open(url)
    }
  }
  upgrade_project(){
    let ip = this.ipTarget && this.ipTarget.value
    let url = document.querySelector('#archive_path').value
    if(url && url!==''){
      url = `${location.protocol}//${location.host}${url}`
    }else{
      return alert('请选择需要下载的版本')
    }
    if(confirm('确定操作？')) scada_app.send('maintenance_action', {action: 'upgrade_project', url, ip})
  }

}
