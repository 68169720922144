import { Controller } from "stimulus"
import { Modal } from "bootstrap/dist/js/bootstrap.bundle"

export default class extends Controller {

  // editModalDom() {
  //   return this.element.closest('.modal')
  // }

  // generateKeyDom() {
  //   return document.getElementById('new-electronic-signature-modal')
  // }

  show(event) {
    const { currentTarget } = event;
    if (currentTarget.closest('.audit-trail-uninitialized')) {
      document.getElementById('btn-initialize-audit-trial').click()

      event.preventDefault()
      event.stopPropagation()
      return
    }

    const modalSelector = this.element.dataset.bsTarget
    const modalDom = document.querySelector(modalSelector);
    const modal = new Modal(modalDom)
    modal.show();

    if (currentTarget.dataset.href) {
      const form = modalDom.querySelector("form")
      form.action = currentTarget.dataset.href
      form.querySelectorAll('.is-invalid').forEach((element) => element.classList.remove('is-invalid'))
      form.querySelectorAll('[type=password]').forEach(e => e.value = '')
    }

    if (currentTarget.dataset.username) {
      const nameElement = modalDom.querySelector(".name")
      nameElement.innerText = currentTarget.dataset.username
    }

    modalDom.addEventListener('shown.bs.modal', () => {
      modalDom.querySelector("[autofocus]").focus()
    })
  }

  close(event) {
    if (event.detail.fetchResponse.response.ok) {
      const modal = Modal.getInstance(this.editModalDom())
      modal.hide();
    }
  }

  // showGenerateKeyModal() {
  //   const editModal = Modal.getInstance(this.editModalDom())
  //   editModal.hide()
  //   const generateKeyModal = new Modal(this.generateKeyDom())
  //   generateKeyModal.show()
  // }
  //
  // generateKeyModalShown() {
  //   const editForm = document.querySelector("#edit-modal form")
  //   const url = editForm.dataset.generateKeyUrl
  //   const generateKeyForm = document.querySelector("#new-electronic-signature-modal form")
  //   generateKeyForm.action = url
  // }
  //
  // generateKeyModalDismiss() {
  //   const modal = Modal.getInstance(this.editModalDom())
  //   modal.show()
  // }
}
