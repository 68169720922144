import {Controller} from "stimulus";
import {createApp, onMounted, ref, computed} from 'vue/dist/vue.esm-bundler.js';

export default class extends Controller {
  connect() {
    createApp({
      setup() {
        const key = ref()
        const machines = ref()
        const loadingStatus = ref(0)

        const activatedMachines = computed(() => machines.value?.length || 0)

        onMounted(async () => {
          const r1 = await fetch("my_development_key", {headers: {Accept: 'application/json'}})
          const {data: [license]} = await r1.json()
          key.value = license.attributes.key
          loadingStatus.value = 1

          const r2 = await fetch(
            `my_development_machines?license_id=${license.id}&license_key=${encodeURIComponent(key.value)}`,
            {headers: {Accept: 'application/json'}}
          )
          const {data} = await r2.json()
          machines.value = data
          loadingStatus.value = 2
        })

        const deactivateMachine = async (id, key) => {
          const confirmed = confirm("您确定要取消激活本机器吗？")
          if (!confirmed) return

          const formData = new FormData()
          formData.append('machine_id', id)
          formData.append('license_key', key)
          const r = await fetch('/admin/biz_users/deactivate_machine', {body: formData, method: 'POST'})
          const data = await r.json()
          machines.value = machines.value.filter(({id: _id}) => _id !== id)
        }

        const downloadKey = (e) => {
          const username = e.target.dataset.username
          const contentType = 'application/octet-stream'
          const a = document.createElement('a')
          const blob = new Blob([key.value], { type: contentType})
          a.href = window.URL.createObjectURL(blob)
          a.download = `${username}.scada_key`
          a.click()
        }

        return {
          activatedMachines,
          maxActivatedMachines: 5,
          machines,
          key,
          loadingStatus,
          deactivateMachine,
          downloadKey
        }
      }
    }).mount(this.element)
  }
}
