import {Controller} from "stimulus";
import {createApp, onMounted, ref, computed} from 'vue/dist/vue.esm-bundler.js';

export default class extends Controller {
  connect() {
    createApp({
      props: {
        value: Number
      },
      setup({value}) {
        const totalSeconds = ref(parseInt(value, 10) || undefined);
        const setDHM = (d, h, m) => {
          totalSeconds.value = (d || 0) * 86400 + (h || 0) * 3600 + (m || 0) * 60
        }
        const enabled = computed({
          get() {
            return !! totalSeconds.value
          },

          set(value) {
            if (!value) {
              totalSeconds.value = undefined
            }
          }
        })
        const days = computed({
          get() {
            return Math.floor(totalSeconds.value / 86400) || undefined
          },
          set(value) {
            setDHM(value, hours.value, minutes.value)
          }
        })
        const hours = computed({
          get() {
            return Math.floor(totalSeconds.value % 86400 / 3600) || undefined
          },
          set(value) {
            setDHM(days.value, value, minutes.value)
          }
        })
        const minutes = computed({
          get() {
            return Math.floor(totalSeconds.value % 86400 % 3600 / 60) || undefined
          },
          set(value) {
            setDHM(days.value, hours.value, value)
          }
        })

        return {
          totalSeconds,
          enabled,
          days,
          hours,
          minutes
        }
      }
    }, {
      value: parseInt(this.element.dataset.value, 10)
    }).mount(this.element)
  }

}
