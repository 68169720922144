import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['form']

    connect() {
        this.id = this.element.dataset.id
        this.project_id = this.element.dataset.projectId
    }
    async edit(){
      let formDiv = this.formTarget
      document.querySelectorAll('.variable-edit-form-div').forEach(el=>{
        if(!el.classList.contains('hidden')) el.classList.add('hidden')
      })
      let res = await fetch(`/admin/projects/${this.project_id}/variables/${this.id}.json`)
      let obj = await res.json()
      formDiv.querySelector("input[name=byte_length]").value= obj.byte_length
      formDiv.querySelector("input[name=name]").value= obj.name
      formDiv.querySelector("input[name=origin_addr]").value= obj.origin_addr
      formDiv.querySelector("select[name=value_type]").value = obj.value_type
      formDiv.classList.remove('hidden')
    }
    async close() {
      this.formTarget.classList.add('hidden')
    }
    async save() {
      let formDiv = this.formTarget
      let updates = {
        byte_length :formDiv.querySelector("input[name=byte_length]").value,
        name: formDiv.querySelector("input[name=name]").value,
        origin_addr: formDiv.querySelector("input[name=origin_addr]").value,
        value_type: formDiv.querySelector("select[name=value_type]").value
      }
      let res = await fetch(`/admin/projects/${this.project_id}/variables/${this.id}.json`,{
        method: 'PATCH',
        body: JSON.stringify({ variable: updates}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': window.CSRF()
        }
      })
      // console.log(res)
      let d = await res.json()
      Turbo.visit(location.href)
    }

    delayedSave() {
        if (self.delayedSaveTimer) {
            clearTimeout(self.delayedSaveTimer)
        }

        self.delayedSaveTimer = setTimeout(() => {
            this.save()
        }, 5000)
    }
}
