import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['input']

    get inputValue() {
        return this.inputTarget.value;
    }
    connect() {
        self.savedValue = this.inputValue;
    }

    async save() {
        self.delayedSaveTimer = null

        if (this.savedValue === this.inputValue) {
            return;
        }

        const id = this.inputTarget.dataset.i18nItemId
        const language = this.inputTarget.dataset.i18nItemLanguage
        const value = this.inputValue
        try {
            await fetch(`i18n_items/${id}`, {
                method: "PATCH",
                body: JSON.stringify({ i18n_item: { values: {[language]: value}}}),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': window.CSRF()
                }
            })

            this.savedValue = value;
        } catch(e) {
            console.log("error: ", e)
        }
    }

    delayedSave() {
        if (self.delayedSaveTimer) {
            clearTimeout(self.delayedSaveTimer)
        }

        self.delayedSaveTimer = setTimeout(() => {
            this.save()
        }, 5000)
    }
}
