import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('[name$="_confirmation]"]').forEach((element) => {
      const passwordFieldName = element.name.replace(/_confirmation]$/, ']');
      const passwordField = this.element.querySelector(`[name="${passwordFieldName}"]`)
      if (passwordField) {
        const changeHandler = ({target}) => {
          if (passwordField.value && element.value && passwordField.value !== element.value) {
            element.setCustomValidity('两次输入的密码不一致')
          } else {
            element.setCustomValidity('')
          }
        }

        element.addEventListener('change', changeHandler)
        passwordField.addEventListener('change', changeHandler)
      } else {
        console.warn('confirmation field found, but found no corresponding password field: ', element)
      }
    })
  }
}
