// 保存一些常用工具方法
// 判断是否为空，包括数组 []， 空的object {}, 都会判断为true
// 注意： is_blank(false)  -->  true
window.is_blank = function(obj){
    if(obj==null || obj==undefined)
        return true
    switch (typeof obj)
    {
        case 'string':
            return obj=='' || obj.trim()=='';
            break;
        case 'object':
            return obj.length==0 || Object.keys(obj).length==0;
            break;
        default:
            return false

    }
}
window.is_present = function(obj){
    return !is_blank(obj)
}


window._$encode = function(_map,_content){
    _content = ''+_content;
    if (!_map||!_content){
        return _content||'';
    }
    return _content.replace(_map.r,function($1){
        let _result = _map[!_map.i?$1.toLowerCase():$1];
        return _result!=null?_result:$1;
    });
};
window._$escape = (function(){
    //,' ':'&nbsp;'
    let _reg = /<br\/?>$/,
        _map = {
            r:/\<|\>|\&|\r|\n|\s|\'|\"/g,
            '<':'&lt;','>':'&gt;','&':'&amp;',
            '"':'&quot;',"'":'&#39;','\n':'<br/>','\r':''
        };
    return function(_content){
        _content = _$encode(_map,_content);
        return _content.replace(_reg,'<br/><br/>');
    };
})();

/* 格式化日期 */
Date.prototype.Format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1,  // 月份
        "d+": this.getDate(),		// 日
        "h+": this.getHours(),		// 小时
        "m+": this.getMinutes(),	// 分
        "s+": this.getSeconds(),	// 秒
        "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
        "S": this.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

// dateFormat(time,"yyyy-MM-dd HH:mm")
window.dateFormat = (function(){
    let _map = {i:!0,r:/\byyyy|yy|MM|cM|eM|M|dd|d|HH|H|mm|ms|ss|m|s|w|ct|et\b/g},
        _12cc = ['上午','下午'],
        _12ec = ['A.M.','P.M.'],
        _week = ['日','一','二','三','四','五','六'],
        _cmon = ['一','二','三','四','五','六','七','八','九','十','十一','十二'],
        _emon = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'];
    let _fmtnmb = function(_number){
        _number = parseInt(_number)||0;
        return (_number<10?'0':'')+_number;
    };
    let _fmtclc = function(_hour){
        return _hour<12?0:1;
    };
    return function(_time,_format,_12time){
        if (!_time||!_format)
            return '';
        _time = new Date(_time);
        _map.yyyy = _time.getFullYear();
        _map.yy   = (''+_map.yyyy).substr(2);
        _map.M    = _time.getMonth()+1;
        _map.MM   = _fmtnmb(_map.M);
        _map.eM   = _emon[_map.M-1];
        _map.cM   = _cmon[_map.M-1];
        _map.d    = _time.getDate();
        _map.dd   = _fmtnmb(_map.d);
        _map.H    = _time.getHours();
        _map.HH   = _fmtnmb(_map.H);
        _map.m    = _time.getMinutes();
        _map.mm   = _fmtnmb(_map.m);
        _map.s    = _time.getSeconds();
        _map.ss   = _fmtnmb(_map.s);
        _map.ms   = _time.getMilliseconds();
        _map.w    = _week[_time.getDay()];
        let _cc   = _fmtclc(_map.H);
        _map.ct   = _12cc[_cc];
        _map.et   = _12ec[_cc];
        if (!!_12time){
            _map.H = _map.H%12;
        }
        return _$encode(_map,_format);
    };
})();


//数组功能扩展
Array.prototype.each = function(fn){
    fn = fn || Function.K;
    let a = [];
    let args = Array.prototype.slice.call(arguments, 1);
    for(let i = 0; i < this.length; i++){
        let res = fn.apply(this,[this[i],i].concat(args));
        if(res != null) a.push(res);
    }
    return a;
};

//数组是否包含指定元素
Array.prototype.contains = function(suArr){
    for(let i = 0; i < this.length; i ++){
        if(this[i] == suArr){
            return true;
        }
    }
    return false;
};
//不重复元素构成的数组
Array.prototype.uniquelize = function(){
    let ra = new Array();
    for(let i = 0; i < this.length; i ++){
        if(!ra.contains(this[i])){
            ra.push(this[i]);
        }
    }
    return ra;
};
//两个数组的补集
Array.complement = function(a, b){
    return Array.minus(Array.union(a, b),Array.intersect(a, b));
};
//两个数组的交集
Array.intersect = function(a, b){
    return a.uniquelize().each(function(o){return b.contains(o) ? o : null});
};
//两个数组的差集
Array.minus = function(a, b){
    return a.uniquelize().each(function(o){return b.contains(o) ? null : o});
};
//两个数组并集
Array.union = function(a, b){
    return a.concat(b).uniquelize();
};
window.show_notice = function(){}
window.error_notice = function(){}
//
// window.error_notice = function(content, notice_type){
//     if(content=='' || content ==undefined)
//         return;
//     if(notice_type && notice_type=='alert'){
//         alert(content)
//         return
//     }
//     // 在modal弹框的时候遮罩层会覆盖message，所以让消息在modal里显示
//     if($('.application-modal:visible').length>0){
//         if($('.application-modal:visible .block-content>.bg-danger').length>0){
//             $('.application-modal:visible .block-content>.bg-danger>span').html(content)
//             $('.application-modal:visible .block-content>.bg-danger').removeClass('hide')
//         }else{
//             $('.application-modal:visible .block-content').prepend('<p class="content-mini content-mini-full bg-danger" style="color: white"><span>' +
//                 content +
//                 '</span><a href="javascript:void(0)" class="pull-right" onclick="$(this).parent().addClass(\'hide\')">关闭</a>' +
//                 '</p>')
//         }
//         return
//     }
//     $.notify({
//             message: content,
//             icon: 'fa fa-times',
//             title: ''
//         },
//         {
//             type: 'danger',
//             placement: {
//                 from: "top",
//                 align: "center"
//             },
//             z_index: 1032
//         });
// }
//
//
// window.show_notice = function(content){
//     if(content=='' || content ==undefined)
//         return
//     $.notify({
//             message: content,
//             icon: 'fa fa-info-circle',
//             title: ''
//         },
//         {
//             type: 'info',
//             placement: {
//                 from: "top",
//                 align: "center"
//             },
//             z_index: 1032
//         });
// }
