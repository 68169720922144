import { Controller } from "stimulus"
import { $ } from "../init_select2"

export default class extends Controller {
  static targets = ['select']
  connect() {
    if(this.selectTarget){
      $(this.selectTarget).select2()
    }
  }
}
