import { Controller } from "stimulus"
import { $ } from "../init_select2"

export default class extends Controller {
  static targets = ['select']
  connect() {
    if(this.selectTarget){
      let project_id = this.selectTarget.dataset.projectId
      let params = this.selectTarget.dataset.params
      if(typeof params==='string') params = JSON.parse(params)
      $(this.selectTarget).select2({
        allowClear: true,
        placeholder: 'Please select variable',
        ajax: {
          delay: 200,
          url: `/admin/projects/${project_id}/variables/options_for_select2`,
          data: function (q) {
            let query = {
              q: q.term,
              page: q.page || 1
            }
            if(typeof params==='object') Object.assign(query, params)
            // Query parameters will be ?search=[term]&page=[page]
            return query;
          }
        }
      })
    }
  }
}
