import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['startBtn', 'stopBtn', 'plcIp', 'plcPort', 'filterName', 'plc', 'variables', 'status', 'time']

    connect () {
        if(window.scada_app==null) return console.log('scada_app null!!!!!')
        this.plc = JSON.parse(this.plcTarget.dataset.value)
        this.variables = JSON.parse(this.variablesTarget.dataset.value)
        this.id_key = `${this.plc.project_id}_${this.plc.id}`
        scada_app.send('simulation_loaded', this.id_key)
        scada_app.receive('simulation_status', this.receiveStatus.bind(this))
        scada_app.receive('collect_data', this.receiveData.bind(this))
    }
    receiveStatus(id, data){
      if(id!==this.id_key) return
      if(data.status==='running'){
        this.startBtnTarget.classList.add('hidden')
        this.stopBtnTarget.classList.remove('hidden')
        this.statusTarget.innerText = data.message
        this.statusTarget.classList.add('text-success')
        this.statusTarget.classList.remove('text-danger')
      }else{
        this.startBtnTarget.classList.remove('hidden')
        this.stopBtnTarget.classList.add('hidden')
        if(data.status==='error'){
          this.statusTarget.classList.remove('text-success')
          this.statusTarget.classList.add('text-danger')
        }else{
          this.statusTarget.classList.add('text-success')
          this.statusTarget.classList.remove('text-danger')
        }
        this.statusTarget.innerText = data.message
      }
    }
    receiveData(data){
      // console.log('receive', id, data)
      // if(id!==this.id_key) return
      for(let full_name in data){
        let dom = document.getElementById(full_name)
        if(dom){
          let label = dom.getElementsByTagName('label')[0]
          if(label) {
            if(typeof data[full_name]==='object') data[full_name] = JSON.stringify(data[full_name])
            label.innerText = data[full_name]
          }
        }
      }
    }
    start_simulation() {
        console.log('start simulate--->', this.plc)
        scada_app.send('start_simulation', { plc: this.plc,  variables: this.variables })
    }
    stop_simulation() {
        scada_app.send('stop_simulation', this.id_key)
    }
    reload_page() {
        scada_app.send('stop_simulation', this.id_key)
        window.location.reload()
    }
    open_write_dialog(e) {
      let div = e.target
      if(!div.classList.contains('open_write_dialog_div')) div = div.parentNode
      if(div.classList.contains('open_write_dialog_div')){
        let id = div.id
        let val = div.getElementsByTagName('label')[0].innerText
        document.getElementById('write_var_name').innerText = id
        document.getElementById('write_var_value').value = val
        document.getElementById('simulation_write_dialog').classList.remove('hidden')
      }
    }
    write_val(){
      let name = document.getElementById('write_var_name').innerText
      let value = document.getElementById('write_var_value').value
      scada_app.send('write_val', [this.plc.project_id, {name, value}] )
      document.getElementById('write_var_notice').classList.remove('hidden')
      if(this.write_notice_timeout){
        clearTimeout(this.write_notice_timeout)
      }
      this.write_notice_timeout = setTimeout(()=>document.getElementById('write_var_notice').classList.add('hidden'), 3000)

    }
    close_write_dialog(){
      document.getElementById('simulation_write_dialog').classList.add('hidden')
    }
    filter_data(){
        let vv = this.filterNameTarget.value.toLowerCase().trim()
        this.variables.forEach(v=>{
            if(vv === ''|| v.name.toLowerCase().indexOf(vv)>=0){
                let dom = document.getElementById(`${this.plc.sn}|${v.name}`)
                dom && dom.classList.remove('hidden')
            }else{
                let dom = document.getElementById(`${this.plc.sn}|${v.name}`)
                dom && dom.classList.add('hidden')
            }
        })
    }
}
