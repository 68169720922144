import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdown", "ul" ]

  connect() {
    const active = this.ulTarget.querySelector(".active");
    if (active)
        this.dropdownTarget.classList.add("active")
  }
}
