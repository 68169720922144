import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['startBtn', 'stopBtn', 'plcIp', 'plcPort', 'filterName', 'plc', 'variables', 'status', 'time']

    connect () {
        if(scada_app==null) return console.log('scada_app null!!!!!')
        this.plc = JSON.parse(this.plcTarget.dataset.value)
        this.id_key = `${this.plc.project_id}_${this.plc.id}`
        this.variables = JSON.parse(this.variablesTarget.dataset.value)
        this.tzoffset = (new Date()).getTimezoneOffset() * 60000;
        scada_app.send('collect_page_loaded', this.id_key)
        scada_app.receive('collect_status', this.receiveStatus.bind(this))
        scada_app.receive('collect_data', this.receiveData.bind(this))
    }
    receiveStatus(d){
        if (d.connected) {
            this.plcIpTarget.disabled = true
            this.startBtnTarget.disabled = true
            this.stopBtnTarget.disabled = false
        } else {
            this.plcIpTarget.disabled = false
            this.startBtnTarget.disabled = false
            this.stopBtnTarget.disabled = true
        }
        this.statusTarget.innerHTML = `<div><i class="${d.connected ? 'connected' : 'disconnected'}"></i>${d.connected ? '已连接' : '已断开'}</div>` +
          `<div>${d.message}</div>`
    }
    receiveData(d){
        this.timeTarget.innerText = (new Date(Date.now() - this.tzoffset)).toISOString().slice(0, -1);
        for (let k in d) {
            let dom = document.getElementById(k)
            if (dom) {
                let label = dom.getElementsByTagName('label')[0]
                if (label) label.innerText = d[k]
            } else {
                let dom = document.createElement('div')
                dom.id = k
                dom.innerHTML = `${k}: <label>${d[k]}</label>`
                data_div.appendChild(dom)
            }
        }
    }
    start_collect() {
        console.log('start_collect--->')
        let ip = this.plcIpTarget.value
        let port = this.plcPortTarget.value
        if(ip!=='') this.plc.ip = ip
        if(port!=='') this.plc.port = port
        scada_app.send('start_collect', { plc: this.plc, variables: this.variables })
    }
    stop_collect() {
        scada_app.send('stop_collect', this.id_key)
    }
    filter_data(){
        let vv = this.filterNameTarget.value.toLowerCase().trim()
        this.variables.forEach(v=>{
            if(vv === ''|| v.name.toLowerCase().indexOf(vv)>=0){
                let dom = document.getElementById(`${this.plc.sn}|${v.name}`)
                dom && dom.classList.remove('hidden')
            }else{
                let dom = document.getElementById(`${this.plc.sn}|${v.name}`)
                dom && dom.classList.add('hidden')
            }
        })
    }
}
