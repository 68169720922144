import { Controller } from "stimulus"
import { parentUntil } from '../helpers'
import 'monaco-editor/esm/vs/editor/browser/controller/coreCommands.js';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js'
export default class extends Controller {
    static targets = ['importField', 'tbody']

    connect () {
        let tbody = this.tbodyTarget
        this.records = JSON.parse(tbody.dataset.records)
        this.languages = JSON.parse(tbody.dataset.languages)
        this.records.forEach(r=>{
            tbody.append(this.generate_tr(r))
        })
        this.add_idx = 0
        this.import_value = ''
        const instance = monaco.editor.create(this.importFieldTarget,{
            value: '\n\n\n\n',
            lineNumbers: 'off',
            insertSpaces: false,
            tabSize: 4,
            minimap: { enabled: false }
        })
        instance.onDidChangeModelContent(e=>{
            this.import_value = instance.getValue()
        })
        document.querySelector('.enumeration-text-import-area').classList.add('hidden')
    }
    add() {
        this.tbodyTarget.append(this.generate_tr())
        // dom.focus()
        // console.log(dom.scrollTop)
    }
    generate_tr(obj = {}){
        let dom = document.createElement('tr')
        let prefix = 'items[]'
        let td1 = document.createElement('td')
        let key_input = document.createElement('input')
        Object.assign(key_input, { type: 'text', name: `${prefix}[value]`, placeholder: "Please input key", value: obj.value||''})
        key_input.classList.add('form-control', 'enum-item-key')
        td1.appendChild(key_input)
        dom.appendChild(td1)
        this.languages.forEach(l=>{
            let lan_td = document.createElement('td')
            let lan_input = document.createElement('input')
            Object.assign(lan_input, { type: 'text', name: `${prefix}[${l}]`, placeholder: "Please input text", value: obj[l]||''})
            lan_input.classList.add(`form-control`, `enum-item-lang-${l}`)
            lan_td.append(lan_input)
            dom.appendChild(lan_td)
        })
        let btn_td = document.createElement('td')
        btn_td.classList.add('text-center')
        btn_td.innerHTML = `<a class="btn btn-danger btn-sm del-btn" data-action="enumeration-item-text-edit#remove" href="javascript: void(0)"><i class="fas fa-minus"></i></a>`
        dom.setAttribute('data-object', JSON.stringify(obj))
        dom.appendChild(btn_td)
        return dom
    }
    show_import(){
        document.querySelector('.enumeration-text-import-area').classList.remove('hidden')
    }
    hide_import(){
        document.querySelector('.enumeration-text-import-area').classList.add('hidden')
    }
    import(){
        this.import_value.split("\n").forEach(r=>{
            let arr = r.trim().split(/\t+/)
            console.log(arr)
            if(arr.length>=2){
                let obj = {value: arr[0]}
                this.languages.forEach((l, i)=>{
                    obj[l] = arr[i+1]||''
                })
                this.tbodyTarget.append(this.generate_tr(obj))
            }
        })
        this.hide_import()
    }
    batch_save(e){
        let form = parentUntil(e.target, 'form')
        form.submit()
    }
    after_save(e){
        if(e.detail[2] && e.detail[2].status===200){

        }
        console.log('after save', e.detail)
    }
    remove(e){
        let tr = parentUntil(e.target, 'tr')
        tr.remove()
    }
}
