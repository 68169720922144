import {Controller} from 'stimulus';

const XLSX_MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export default class extends Controller {
  static targets = ['form', 'uploadButton', 'fileInput']

  connect() {
    console.log("connected")
    this.element.addEventListener('ajax:success', (e) => {
      console.log("ajax success")
    })
  }

  async submit(e) {
    // show loading
    this.uploadButtonTarget.classList.add('loading', 'disabled')

    try {
      const response = await fetch(this.formTarget.action, {
        credentials: 'include',
        method: 'POST',
        body: new FormData(this.formTarget)
      })

      if (response.ok) {
        alert("该审计追踪日志有效")
      } else {
        switch(response.headers.get('Content-Type')) {
          case XLSX_MIME:
            const blob = await response.blob()
            const utf8Filename = response.headers.get('Content-Disposition').match(/filename\*=UTF-8''(.*)\b/)[1]

            const temporaryLink = document.createElement('a')
            temporaryLink.href = URL.createObjectURL(blob)
            temporaryLink.download = decodeURIComponent(utf8Filename)
            document.body.appendChild(temporaryLink)
            temporaryLink.click()
            temporaryLink.remove()
            URL.revokeObjectURL(temporaryLink.href)
            alert('审计日志包含错误，详情已作为Excel下载到电脑上')
            break;
        }
        const text = await response.text()
        alert("该审计追踪日志包含错误：" + text)
      }
    } catch (e) {
      const responseBody = await response.json()
      responseBody.errors
    } finally {
      // stop show loading
      this.uploadButtonTarget.classList.remove('loading', 'disabled')
      this.fileInputTarget.value = ''
    }
  }

  openFileChooser(e) {
    if (!e.target.classList.contains("disabled")) {
      this.fileInputTarget.click()
    }
  }
}
