import {Controller} from "stimulus";
import {createApp, reactive, toRefs, watch, h, compile} from 'vue';
import {Toast} from "bootstrap/dist/js/bootstrap.bundle";

const queryBuilder = function (obj, prefix) {
  var str = [],
      p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
      str.push((v !== null && typeof v === "object") ?
          queryBuilder(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}


export default class extends Controller {
  connect() {
    document.title = "项目列表 - SCADA"
    const CustomCard = {
      template: "#custom-card",
      props: {
        'projects': {
          default: []
        },
        'click': {
          default: () => ''
        },
        'customClass': {
          default: () => () => ''
        },
        'edit': {
          default: () => {
          }
        },
        'destroy': {
          default: () => {
          }
        }
      }
    }
    createApp({
      components: {
        CustomCard
      },
      setup() {
        const in_electron = "scada_app" in window
        const state = reactive({
          projects: [],
          recentProjects: [], // 最近打开的项目
          faceplateLibraries: [], //is_faceplate_library的projects
          activeTab: 'open',
          errorTip: '',
          params: {marked: 0, page: 1, per_page: 12, totalPage: 0},
          searchParams: {},
        })

        if (in_electron) {
          scada_app.receive('preferences_updated', () => {
            //创建项目后，刷新列表
            handleTabClick('mine');
          })
        }

        const TABS = [
          {
            label: '最近打开',
            value: 'open'
          },
          {
            label: '项目列表',
            value: 'mine'
          },
          {
            label: 'faceplate',
            value: 'faceplate'
          }
        ]

        /**
         * watch
         */
        // watch(state.searchParams, (aa) => {
        //   handleSearch()
        // })

        /**
         * methods
         */
        const debounce = (fn, waitTime = 500) => {
          let timer = null
          return function (...args) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
              fn(args)
            }, waitTime)
          }
        }
        const showToast = (text) => {
          const toastLiveExample = document.getElementById('liveToast')
          const toast = new Toast(toastLiveExample)
          state.errorTip = text
          toast.show()
        }

        const fetchApi = async (api, params) => {
          if (params) {
            let query = queryBuilder(params)
            api = `${api}?${query}`
          }
          try {
            const r1 = await fetch(api, {headers: {Accept: 'application/json'}})
            const res = await r1.json()
            return res
          } catch (e) {
            console.warn(e)
            showToast(`${e}`)
            return null
          }
        }
        // 获取最近打开
        const getRecentProjects = async () => {
          const {rows} = await fetchApi("/admin/projects/recent", {page: 1, per_page: 15})
          state.recentProjects = rows
        }

        const fetchProjects = async (extraQuery = {}) => {
          const api_url = "/admin/projects"
          let params = {...state.params}
          // if (state.activeTab === 'open') {
          params = {...params, q: {...state.searchParams, ...extraQuery}}
          // }
          const {rows, total} = await fetchApi(api_url, params)
          return {rows, total}
        }

        // 获取所有项目
        const getAllProjects = async () => {
          const {rows, total} = await fetchProjects({is_faceplate_library_eq: false})
          // console.log('getAllProjects:', rows, total/state.params.per_page)
          state.projects = rows
          state.params.totalPage = Math.ceil(total / state.params.per_page)
        }

        // 获取所有项目
        const getFaceplateLibraries = async () => {
          const {rows, total} = await fetchProjects({is_faceplate_library_eq: true})
          // console.log('getAllProjects:', rows, total/state.params.per_page)
          state.faceplateLibraries = rows
          state.params.totalPage = Math.ceil(total / state.params.per_page)
        }

        // 搜索
        const handleSearch = debounce(() => {
          // state.params = { page: 1, per_page: 12, totalPage: 0 }
          if (state.activeTab === 'mine') {
            getAllProjects()
          } else {
            getFaceplateLibraries()
          }
        })

        // 切换页数
        const changePage = (val) => {
          if (val < 1 || val > state.params.totalPage || state.params.page == val) return
          state.params.page = val
          if (state.activeTab === 'mine') {
            getAllProjects()
          } else {
            getFaceplateLibraries()
          }
        }

        const handleTabClick = (val) => {
          state.activeTab = val
          const per_page = 12
          state.params = {page: 1, per_page, totalPage: 0}
          const fetchActionMap = {
            'mine': getAllProjects,
            'open': getRecentProjects,
            'faceplate': getFaceplateLibraries,
          }
          fetchActionMap[state.activeTab]?.()
        }

        const onDestroy = (id) => {
          confirm1({
            content: '确认删除该项目吗？', onOk: () => {
              const csrfToken = document.querySelector("[name='csrf-token']").content
              fetch(`/admin/projects/${id}`, {
                headers: {
                  Accept: 'application/json',
                  "X-CSRF-Token": csrfToken
                }, method: "DELETE"
              })
                  .then((res) => res.json())
                  .then(() => {
                    getAllProjects();
                    getRecentProjects();
                  })
            }
          })
        }

        const goDetail = (id) => {
          // location.href = in_electron ? `/draw/?project_id=${id}` : `/admin/projects/${id}/open`
          location.href = `/draw/v2/#/projects/${id}`;
        }
        const goPreferences = (id) => {
          window.open(`//${location.host}/preferences/?project_id=${id}`)
          // window.open(`/admin/projects/${id}/open`)
          // window.open(in_electron ? `${location.host}/preferences/?project_id=${id}` : `${location.host}/admin/projects/${id}/open`)
        }

        const goNew = () => {
          // window.open(`/admin/projects/new`, '_blank')
          window.open(`//${location.host}/preferences/#/project/create`)
        }

        /**
         * created
         */
        getRecentProjects()
        // getAllProjects()

        // onMounted(async () => {
        //   const r1 = await fetch("/admin/projects", {headers: {Accept: 'application/json'}})
        //   const {rows, total} = await r1.json()
        //   console.log('projects', rows, total)
        //   state.projects = rows.splice(0, 5)
        //   state.loadingStatus = 1
        // })


        return {
          ...toRefs(state),
          TABS,
          handleTabClick,
          changePage,
          getAllProjects,
          goDetail,
          goPreferences,
          handleSearch,
          in_electron,
          goNew,
          onDestroy
        }
      }
    }).mount(this.element)
  }
}
