import { Controller } from 'stimulus'
import { parentUntil } from '../helpers'
import 'monaco-editor/esm/vs/editor/browser/controller/coreCommands.js'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js'

export default class extends Controller {
  static targets = ['records', 'importField', 'tbody', 'alarmDetail']

  connect () {
    let obj = this.recordsTarget
    this.records = JSON.parse(obj.dataset.records)
    this.languages = JSON.parse(obj.dataset.languages)
    Object.keys(this.records || {}).sort((a,b)=>parseInt(a)-parseInt(b)).forEach(r => {
      let _obj = this.records[r]
      _obj.idx = r
      this.tbodyTarget.append(this.generate_tr(_obj))
    })
    this.import_value = ''
    const instance = monaco.editor.create(this.importFieldTarget, {
      value: '\n\n\n\n',
      lineNumbers: 'off',
      insertSpaces: false,
      tabSize: 4,
      minimap: { enabled: false }
    })
    instance.onDidChangeModelContent(e => {
      this.import_value = instance.getValue()
    })
    document.querySelector('.rule-import-text-area').classList.add('hidden')
  }

  add () {
    this.tbodyTarget.append(this.generate_tr())
    let trs = this.tbodyTarget.querySelectorAll('tr')
    trs[trs.length-1].querySelector('input').focus()
  }
  submit () {
    let res = {}
    let trs = this.tbodyTarget.querySelectorAll('tr')
    for(let i = 0;i<trs.length;i++){
      let tr = trs[i]
      let _detail = {}
      let inputs = tr.querySelectorAll('input')
      let idx_input, idx
      inputs.forEach(x=>{
        if(x.name!=='idx'){
          _detail[x.name] = x.value
        }else{
          idx_input = x
          idx = x.value
        }
      })
      if(idx.trim()==='' || res.hasOwnProperty(idx)){
        idx_input.focus()
        return
      }
      res[idx] = _detail
    }
    this.alarmDetailTarget.value = JSON.stringify(res)
    this.element.submit()
  }

  generate_tr (obj = {}) {
    let dom = document.createElement('tr')
    let inner_html = `<td>
            <input type="text" name="idx" placeholder="Index" value="${obj.idx || ''}" class="form-control enum-item-key">
            </td>`
    this.languages.forEach(l => {
      inner_html = inner_html + `<td><input type="text" name="${l}" value="${obj[l] || ''}" placeholder="${l} text" class="form-control enum-item-lang-${l}"></td>`
    })
    inner_html = inner_html + `<td class="text-center">
            <a class="btn btn-danger btn-sm del-btn" data-action="alarm-rule-edit#remove" href="javascript: void(0)"><i class="fas fa-minus"></i></a>
            </td>`
    // dom.setAttribute('data-object', JSON.stringify(obj))
    dom.innerHTML = inner_html
    return dom
  }

  show_import () {
    document.querySelector('.rule-import-text-area').classList.remove('hidden')
  }

  hide_import () {
    document.querySelector('.rule-import-text-area').classList.add('hidden')
  }

  import () {
    this.import_value.split('\n').forEach(r => {
      let arr = r.trim().split(/\t+/)
      if (arr.length >= 2) {
        let obj = { idx: arr[0] }
        this.languages.forEach((l, i) => {
          obj[l] = arr[i + 1] || ''
        })
        this.tbodyTarget.append(this.generate_tr(obj))
      }
    })
    this.hide_import()
    let trs = this.tbodyTarget.querySelectorAll('tr')
    trs[trs.length-1].querySelector('input').focus()
  }

  batch_save (e) {
    let form = parentUntil(e.target, 'form')
    form.submit()
  }

  after_save (e) {
    if (e.detail[2] && e.detail[2].status === 200) {

    }
    console.log('after save', e.detail)
  }

  remove (e) {
    let tr = parentUntil(e.target, 'tr')
    tr.remove()
  }
}
