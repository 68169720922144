import { Controller } from "stimulus"
import 'monaco-editor/esm/vs/editor/browser/controller/coreCommands.js';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js'
import 'monaco-editor/esm/vs/basic-languages/html/html.contribution'
export default class extends Controller {
    static targets = ['input', 'editor']

    connect () {
        let val = this.inputTarget.value
        if(val==null||val==='') val = "<!-- 请输入HTML代码 -->\n\n\n"
        const instance = monaco.editor.create(this.editorTarget,{
            value: val,
            theme: 'vs',
            formatOnPaste: true,
            language: "html"
        })
        instance.onDidChangeModelContent(e=>{
            this.inputTarget.value = instance.getValue()
        })
    }
}
