import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('input[type=radio]').forEach((element) => {
      element.addEventListener('change', (event) => {
        const { target } = event
        if (target.checked) {
          const { value } = target

          const form = this.element.closest('form')
          form.dataset.operationType = value
        }
      })
    })
  }
}
