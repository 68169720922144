// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import {updateI18nItems} from "../api";

export default class extends Controller {
  static targets = [ "localeInput", "localeText" ]

  connect() {
    // console.log("it works")
  }

  resetTable() {
    this.localeTextTargets.forEach((text) => {
      return text.style.display = 'block'
    })
    this.localeInputTargets.forEach((input) => {
      return input.style.display = 'none'
    })
  }

  async inputComplete(event) {
    const {itemId} = event.target.dataset
    let res = await updateI18nItems(itemId, event.target.value)
    if (res.data) {
      let targetText = this.localeTextTargets.find((input) => {
        return input.dataset.itemId === itemId
      })
      targetText.textContent = event.target.value
      this.resetTable()
    }
  }

  inputCompleteWithEnter(event) {
    if (event.keyCode === 13) {
      const { itemId } = event.target.dataset
      let targetInputField = this.localeInputTargets.find((input) => {
        return input.dataset.itemId === itemId
      })
      targetInputField.blur()
    }
  }

  startInput(event) {
    const { itemId } = event.target.dataset
    this.resetTable()
    let targetInputField = this.localeInputTargets.find((input) => {
      return input.dataset.itemId === itemId
    })
    let targetText = this.localeTextTargets.find((input) => {
      return input.dataset.itemId === itemId
    })
    targetText.style.display = "none"
    targetInputField.style.display = "block"
    targetInputField.focus()
  }
}
